.Button__before .icon{
	margin-right: 8px;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon12 {
	width: 12px;
	height: 12px;
}

.icon14 {
	width: 14px;
	height: 14px;
}

.icon16 {
	width: 16px;
	height: 16px;
}

.icon18 {
	width: 18px;
	height: 18px;
}

.icon20 {
	width: 20px;
	height: 20px;
}

.icon22 {
	&.blue.icon--active path {
		fill: #3f8ae0;
		stroke: #3f8ae0;
		opacity: 1;
	}

	&.blue path {
		fill: transparent;
		stroke: #99a2ad;
	}
}

.icon24 {
	width: 24px;
	height: 24px;
}

.icon28 {
	width: 28px;
	height: 28px;
}

.icon40 {
	width: 40px;
	height: 40px;
}

.icon56 {
	width: 56px;
	height: 56px;
}
