.rating-set {
	display: flex;
	padding-top: 2px;
	padding-bottom: 2px;
}

.icon-rating {
	display: flex;

	&:not(:last-child) {
		margin-right: 12px;
	}

	&.empty svg {
		transform: scale(0.9);

		path{
			fill: #fff;
			stroke: #99a2ad;
			stroke-width: 1.5px;
		}
	}
}
