.rating-stars {
	display: flex;

	> div + div {
		margin-left: 3px;
	}

	.icon--active {

		path {
			fill: #ffd300;
			stroke: #ffd300;
		}
	}

	&.select {

		.icon svg path {
			fill: #aaaeb3;
			stroke: #aaaeb3;
		}
	}

	.white {

		&.icon--active path {
			fill: #ffffff;
			stroke: #ffffff;
			opacity: 1;
		}

		path {
			opacity: .4;
			fill: #ffffff;
			stroke: #ffffff;
		}
	}

	.blue {
		&.icon--active path {
			fill: #3f8ae0;
			stroke: #3f8ae0;
			opacity: 1;
		}

		path {
			fill: transparent;
			stroke: #99a2ad;
		}
	}


}

.rating-review {
	display: flex;

	.rating-stars {
		margin-left: 0;
	}

	&--amount {
		margin-left: 7px;
		font-size: 13px;
		line-height: 1.23;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #76787a;
		color: var(--text_subhead);
	}
}
