.best-author{
	padding-bottom: 4px;

	&__item {
		width: 80px;
		background: transparent;
	}

	&__text {
		margin-top: 2px;
		margin-bottom: 2px;
		padding-left: 6px;
		box-sizing: border-box;
		min-width: 0;
		max-width: 100%;
		overflow: hidden;
		flex-grow: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #000000;
		color: var(--text_primary);
	}

	&__subtext {
		padding-left: 6px;
		box-sizing: border-box;
		min-width: 0;
		max-width: 100%;
		overflow: hidden;
		flex-grow: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #818c99;
		color: var(--text_secondary);
	}

	&.CardScroll--android .CardScroll__in:before {
		width: 8px;
	}

	&.CardScroll--ios .CardScroll__in:before {
		width: 4px;
	}

	.Card {
		margin-right: 2px;
	}

	.Card__in {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 12px;
		line-height: 1.17;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
	}

	.Avatar {
		margin-top: 4px;
		margin-bottom: 4px;
	}
}
