.top-marathon {
	margin-top: 8px;
	overflow: hidden;
	background: #ffffff;
	background: var(--background_content);
	border-radius: 10px;
	box-shadow: 0 2px 24px rgba(0, 0, 0, .08), 0 0 2px rgba(0, 0, 0, .08);
	box-shadow: 0 2px 24px var(--background_highlighted), 0 0 2px var(--background_highlighted);

	&__image {
		height: 83px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__info {
		padding: 12px 16px 16px;
		display: flex;
		flex-direction: column;
	}

	&__name {
		font-size: 17px;
		line-height: 1.29;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.41px;
		color: #000000;
		color: var(--text_primary);
	}

	&__stats {
		margin-top: 4px;
		margin-bottom: 6px;
		font-size: 15px;
		line-height: 1.33;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.24px;
		color: #818c99;
		color: var(--text_secondary);
	}
}
