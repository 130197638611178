.welcome {
	font-family: SFPro;

	&-image {
		margin-top: 50px;
		margin-right: 4px;
		margin-left: 4px;
		max-width: 100%;
	}

	&-title {
		margin-top: 106px;
		margin-bottom: 12px;
		font-family: SFProD;
		font-size: 24px;
		line-height: 1.17;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: .33px;
		color: #000000;
		color: var(--text_primary);
	}

	&-subtitle {
		margin-bottom: 240px;
		font-size: 16px;
		line-height: 1.25;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: -.32px;
		color: #6d7885;
		color: var(--text_subhead);
	}

	.Button {
		margin-top: 8px;
	}

	&-bottom {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.Link {
		position: relative;
	}

	.tooltip {
		left: 50%;
		bottom: calc(100% + 8px);
		width: 203px;
		transform: translateX(-50%);
	}
}

.onboard {

	&-title {
		margin: 20px 0 12px 0;

		font-family: SFProD;
		font-size: 24px;
		line-height: 1.17;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: .33px;
		color: var(--text_primary);
	}

	&-subtitle {
		margin-bottom: 60px;

		font-family: SFPro;
		font-size: 16px;
		line-height: 1.25;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: -.32px;
		color: var(--text_subhead);
	}
}

.layout-white {
	background: #ffffff;
	background: var(--background_content);
}
