.marathon-about {

	&__cover {
		height: 83px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
	}

	&__name {
		margin-top: 12px;
		font-family: SFProD, sans-serif;
		font-size: 24px;
		line-height: 1.17;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: .33px;
		color: var(--text_primary);
	}

	&__stats, &__done {
		margin-top: 4px;
		display: flex;
		align-items: center;
		font-size: 13px;
		line-height: 1.23;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: var(--text_secondary);

		span {
			margin-right: 9.5px;
		}
	}

	&__done {
		margin-top: 8px;
	}

	&__action {
		margin-top: 16px;
		display: flex;

		.Button + .Button {
			margin-left: 8px;
		}
	}

	&__description {
		margin-top: 16px;
		font-size: 16px;
		line-height: 1.25;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.32px;
		color: var(--text_primary);
	}

	&__video {
		position: relative;

		margin-top: 20px;
		width: 100%;
		min-height: 197.4px;
		border: solid .5px var(--background_highlighted);
		border-radius: 8px;

		iframe {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;
			border-radius: 8px;
		}
	}

	&__author {
		margin-top: 7px;
		padding-top: 0;
		padding-bottom: 0;
	}
}
