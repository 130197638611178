#test {
	.FixedLayout--bottom {
		background-color: var(--background_content);
	}
}

.block-test {
	display: flex;
	flex-direction: column;
	width: calc(100% - 64px);
	margin: 0 auto;

	font-family: SFPro, sans-serif;

	&.extra-padding {
		padding-bottom: 45px;
	}

	&__title {
		margin-bottom: 22px;

		font-size: 17px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: -0.41px;
		text-align: center;
		color: var(--text_primary);
	}

	&__subtitle {
		margin-bottom: 8px;

		font-family: SFPro, sans-serif;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: -0.15px;
		color: #6d7885;
	}

	&__btn-wrapper {
		padding: 0 16px;
	}

	&__texted-answer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		label {
			margin-bottom: 8px;

			font-family: SFPro, sans-serif;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.29;
			letter-spacing: -0.15px;
			color: #6d7885;
		}

		.Input {
			width: 100%;
		}
	}
}

.test-pairs {
	display: flex;
	justify-content: space-between;
}

.pairs {

	&--column {
		width: calc(100% / 2 - 10px);
	}

	&--content-box {

	}

	&--box {
		display: flex;
		align-items: flex-start;
		align-self: flex-start;
		box-sizing: border-box;
		width: 100%;
		min-height: 44px;
		margin-bottom: 15px;
		padding: 12px;

		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: var(--text_primary);
		word-wrap: break-word;
		line-break: anywhere;

		border-radius: 10px;
		border: 1px solid #d3d9de;
		background-color: var(--field_background);
		transition: margin-bottom 0.1s ease-in-out;

		.icon-reorder {
			position: absolute;
			top: 12px;
			right: 12px;
		}
	}

	&--drag {
		padding-right: 40px;
		cursor: pointer;
		position: relative;

		&:hover:not(:active) .tooltip {
			display: block;
		}

		.tooltip {
			bottom: 95%;
			left: 50%;
			transform: translateX(-50%);
		}

		&:active {
			cursor: grabbing;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
		}
	}
}

.radio-custom {
	padding-left: 0 !important;
}
