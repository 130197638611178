.category-check {

	.Checkbox {
		padding: 0;

		&__icon {
			margin: 0;
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;

			.Icon {
				padding: 0 !important;
				color: transparent !important;
			}
		}

		&__input:checked~ .Checkbox__container .Checkbox__icon .Icon {
			display: block;
			color: var(--white) !important;
		}
	}
}
