@font-face { font-family: 'SFPro'; font-weight: 400; font-style: normal; src: url('../font/SFProText-Regular.ttf'); }
@font-face { font-family: 'SFPro'; font-weight: 500; font-style: normal; src: url('../font/SFProText-Medium.ttf'); }
@font-face { font-family: 'SFProD'; font-weight: 400; font-style: normal; src: url('../font/SFProDisplay-Regular.ttf'); }
@font-face { font-family: 'SFProD'; font-weight: 500; font-style: normal; src: url('../font/SFProDisplay-Medium.ttf'); }

body {
	user-select: none;
	* {
		user-select: none;
	}

	input, input:before, input:after {
		-webkit-user-select: initial;
		-khtml-user-select: initial;
		-moz-user-select: initial;
		-ms-user-select: initial;
		user-select: initial;
	}
}

#main {
	font-family: SFPro;
}

.Tabbar--ios.Tabbar--shadow:before {
	bottom: 98%;
}

.icon-before {
	margin-right: 12px;
}

.icon-category {
	width: 48px;
	height: 48px;
	background-repeat: no-repeat;
	background-size: contain;
}

.Button {

	&__before .Icon{
		margin-right: 6px;
	}

	&__content {
		font-size: 15px !important;
		line-height: 1.33;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.24px;
	}
}

.PanelHeader__content {
	font-family: SFProD;
	font-size: 20px !important;
	line-height: 1.2 !important;
	font-weight: 600 !important;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: .38px;
}

.Avatar__shadow {
	box-shadow: inset 0 0 0 .5px rgba(0,0,0,.08);
	box-shadow: inset 0 0 0 .5px var(--image_border);
}

.SimpleCell {
	padding-top: 6px;
	padding-bottom: 6px;

	&--ios .SimpleCell__main{
		font-size: 16px;
		line-height: 1.25;
		letter-spacing: -.32px;
	}

	&--android .SimpleCell__main{
		font-size: 17px;
		line-height: 1.25;
		letter-spacing: -.32px;
	}

	&--mult .SimpleCell__children{
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&--mult .SimpleCell__description {
		text-overflow: ellipsis;
	}
}

.RichCell {
	padding-top: 6px;
	padding-bottom: 6px;

	&__children {
		font-size: 16px;
		line-height: 1.25;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.32px;
	}

	&__caption {
		margin-top: 3px;
	}

	&__actions {
		margin-top: 6px;
	}
}

.tab-offset {
	margin-top: 52px;
}

.TabsItem {
	letter-spacing: -.32px;
}

.tabs-scroll {

	.CardScroll__in:before, .CardScroll__in:after {
		width: 0;
	}
}

.empty-tab {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translate(-50%, -50%);

	&__title {
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 1.25;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		white-space: nowrap;
		letter-spacing: -.32px;
		color: #818c99;
		color: var(--text_secondary);
	}
}

.empty-search {
	padding-top: 48px;
	font-size: 16px;
	line-height: 1.25;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	text-align: center;
	white-space: nowrap;
	letter-spacing: -.32px;
	color: #818c99;
	color: var(--text_secondary);
}

.program-item {
	padding-top: 0;
	padding-bottom: 0;

	.SimpleCell__description {
		display: flex;
		align-items: center;

		.Icon {
			margin-left: 6px;
		}
	}
}

.hide {
	display: none;
}

.Avatar__img {
	object-fit: cover;
}

#about-marathon,
#about-author,
#category,
#about-marathon-category,
#about-author-category,
#about-marathon-profile {
	.PanelHeader__content {
		width: 50%;
	}
}

#test {
	.Panel__centered {
		overflow: scroll;
	}
}

.panel-category__dummy {
	font-family: SFPro, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: var(--text_secondary);
}
