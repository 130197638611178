.ModalCard {
	font-family: SFPro, sans-serif;

	&__title {
		font-family: SFProD, sans-serif;
		letter-spacing: .38px;
	}

	&__subtitle {
		margin-top: 13px;
		margin-bottom: 16px;
		font-size: 13px;
		line-height: 1.25;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		text-transform: uppercase;
		color: #6d7885;
		color: var(--text_subhead);

		&--closed {
			margin-top: 9.5px;

			font-family: SFPro, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			color: var(--accent);
		}
	}

	&__caption {

		span {
			margin-bottom: 4px;
			display: block;
			letter-spacing: -.15px;

			& + .Button {
				margin-bottom: -7px;
			}
		}
	}

	.rating-set {
		justify-content: center;
	}
}
