.tooltip {
	position: absolute;
	z-index: 1;
	padding: 8.5px 12px;
	box-sizing: border-box;
	//display: none;
	font-size: 14px;
	line-height: 1.29;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: -.15px;
	pointer-events: none;
	color: #2c2d2e;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 32px 0 rgba(0, 0, 0, .16), 0 0 4px 0 rgba(0, 0, 0, .04);

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 20px;
		height: 8px;
		background: url('../image/corner.png') center no-repeat;
		background-size: contain;
		transform: translateX(-50%);
	}

	&.active {
		display: block;
	}
}
