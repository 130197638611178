.author-about {
	position: relative;
	z-index: 10;
	margin-top: -8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__name {
		margin-top: 12px;
		font-family: SFProD;
		font-size: 20px;
		line-height: 1.2;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: .38px;
		color: #000000;
		color: var(--text_primary);
	}

	&__stats {
		margin-top: 4px;
		margin-bottom: 16px;
		font-size: 13px;
		line-height: 1.23;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #818c99;
		color: var(--text_secondary);
	}
}
